import React, { Component } from 'react';
import {Paper, Typography} from '@material-ui/core';

export default class ViewPost extends Component {
    constructor() {
        super();
        this.state = {
        }
    }

    render() {
        return(
            <div>
                <Paper elevation={3} style={{padding: 10, margin:10, paddingRight:100, paddingLeft:100}}>
                    <Typography variant="h2">{this.props.title}</Typography>
                    <Typography variant="h4">by {this.props.username}</Typography>
                    <Typography variant="p">{this.props.content}</Typography>
                </Paper>
                
            </div>)
    }
}

