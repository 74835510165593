import React, { Component } from 'react';
import ViewPost from './viewPost.js';
const axios = require('axios');

const url = "https://my-worker.asriram830647.workers.dev"

export default class ViewPostGrid extends Component {
    constructor() {
        super();
        this.state = {
            posts: [],
            arr: [1, 2, 3, 4]
        }
    }

    getPosts = () => {
        axios.get(url + "/posts").then((res) => {
            this.setState({posts: res.data.postsView})
        }).catch((err) => console.log(err))
    }

    componentDidMount() {
        this.getPosts();
    }

    componentDidUpdate() {
        this.getPosts();
    }

    render() {
        return(<div>{this.state.posts.map((post, index) => 
            <ViewPost key={index} title={post.title} username={post.username} content={post.content}/>
        )}</div>)
    }
}