import logo from './logo.svg';
import './App.css';
import SubmitPost from './components/submitPost';
import ViewPostGrid from './components/viewPostGrid';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <SubmitPost></SubmitPost>
        <ViewPostGrid></ViewPostGrid>
      </header>
    </div>
  );
}

export default App;
