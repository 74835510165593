import React, { Component } from 'react';
import {Paper, Typography} from '@material-ui/core';

const axios = require('axios');

const url = "https://my-worker.asriram830647.workers.dev"

const myUsername = "Sriram"

export default class SubmitPost extends Component {
    constructor() {
        super();
        this.state = {
            title: "",
            content: "",
            username: myUsername
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    

    sendPost = () => {
        axios.post(url + '/posts', {
            title: this.state.title,
            username: this.state.username,
            content: this.state.content
        }).then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});  
    }

    handleSubmit(event) {
        this.sendPost()
        event.preventDefault();
    }

    
    render() {
        return(<div>
            <form onSubmit={this.handleSubmit}>
                <label>
                    Title:
                    <input type="text" name="title" value={this.state.text} onChange={this.handleChange}/>
                </label>
                <label>
                    Content:
                    <input type="text" name="content" value={this.state.content} onChange={this.handleChange}/>
                </label>
                <input type="submit" value="Submit" />
            </form>
        </div>)
    }
}